import Layout from 'components/layout';
import { Carousel, Modal, Row, Col } from 'antd';
import style from 'styles/home/index.module.scss';
import Image from 'components/image';
import CdnImage from 'components/image/cdnImage';
import cn from 'classnames';
import { useRef, useState } from 'react';
import Button from 'components/button';
import ContactBtnGroup from 'components/contact';
import { actionServer } from 'action/index';
import { GetStaticProps } from 'next';
import { advertiseList, IAdvertise, solutionList, ISolution } from 'constants/index';
import { useRouter } from 'next/router';
import Loading from 'components/loading';
import UseResize from 'utils/hooks/useResize';
import CustomLink from 'components/link';

const mp4Url = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/b456ec7876112be44741308cac4a8f03950efeda22d12830b7cd4b049c6bf21d.mp4';
const muteMp4Url = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/3fac15ca1bdf4d4f3d63d796456348e633a906f11ed6646cbe2bd0398ca1b9d2.mp4';
const mutePoster = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/f6934c353137328c363c96e836af8f08ba2c7a608446df33a20fd9f7d40461ed.jpg';
const pcFontImg = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/6b8c2531d3c386235ef8282248104a3b33e32cdeeaf4efecc5fbc0f9b8bf15d7.png';
const mapPoster = 'https://devcdn.xylink.com/xylink_website_doc/images/replace/landing/pc/poster-03-new.jpg';
const mapMp4Url = 'https://devcdn.xylink.com/xylink_website_doc/static_video/landing-lasted.mp4';
const pcPartnerImg = 'http://sdk-source.xylink.com/website/assets/home/C.jpg';
const mPartnerImg = 'http://sdk-source.xylink.com/website/assets/home/logo-%E7%A7%BB%E5%8A%A8%E7%AB%AF-tuya.jpg';
export default function IndexView(prop: any) {
  const [videoVisible, setVideoVisible] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { news } = prop;
  const playVideo = (bool: boolean) => {
    setVideoVisible(bool);
    if (bool) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  };
  const router = useRouter();
  if (router.isFallback) {
    return <Loading />;
  }
  const size = UseResize();

  return (
    <Layout>
      <section className={style.advertise}>
        <Carousel
          autoplay
          autoplaySpeed={4000}
          dots={{
            className: style.dots,
          }}
        >
          {advertiseList.map((item: IAdvertise) => {
            return (
              <div key={item.key}>
                <div className={cn(style.advertiseItem, style[item.key])}>
                  {item.imageUrl && (
                    <div className={cn(style.subImg, style['for-pc'])}>
                      <CdnImage src={item.imageUrl} alt="banner" styles={{ width: '100%', height: '100%' }} />
                    </div>
                  )}
                  {item.key === 'cloudMeeting' && (
                    <div className={cn(style.subText, style['for-mobile'])}>
                      <p>小鱼易连</p>
                      <p>人人都会用的云视频会议</p>
                    </div>
                  )}
                  <div className={style.btnWrapper}>
                    {item.try && (
                      <CustomLink isNextLink={false} href={'/try'}>
                        <Button category="banner" type="primary" color="green" className={style.btnTry}>
                          立即试用
                        </Button>
                      </CustomLink>
                    )}
                    {item.link && (
                      <CustomLink isNextLink={false} href={item.link}>
                        <Button category="banner" type="primary">
                          查看详情
                        </Button>
                      </CustomLink>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </section>
      <section className={style.videoWrapper}>
        <video
          className={style['for-pc']}
          autoPlay
          muted
          loop
          src={muteMp4Url}
          poster={mutePoster}
          webkit-playsinline="true"
          playsInline={true}
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-ignore-metadata="true"
          preload="auto"
        >
          您的浏览器不支持video
        </video>
        <div className={style.subImg}>
          <CdnImage className={cn(style.fontImg, style['for-pc'])} src={pcFontImg} alt="font" />
          <div className={cn(style.fontText, style['for-mobile'])}>小鱼易连官方宣传片</div>
          <Image className={style.playBtn} src="/v2/images/home/pc-play.png" alt="playBtn" onClick={() => playVideo(true)} />
        </div>
        <div className={style.shadow} />
      </section>
      <section className={style.videoMeeting}>
        <div className={style.main}>视频会议，不再设限</div>
        <CustomLink isNextLink={false} href={'/platform'}>
          <div className={style.sub}>
            <span>了解超融合视频平台</span>&nbsp;&gt;
          </div>
        </CustomLink>
      </section>
      <section className={style.smartHard}>
        <div className={style.main}>造作，一身才华</div>
        <CustomLink isNextLink={false} href={'/hardware'}>
          <div className={style.sub}>
            <span>了解智能硬件</span>&nbsp;&gt;
          </div>
        </CustomLink>
      </section>
      <section className={style.mapWrapper}>
        <video
          className={style['for-pc']}
          autoPlay
          muted
          loop
          src={mapMp4Url}
          poster={mapPoster}
          webkit-playsinline="true"
          playsInline={true}
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-ignore-metadata="true"
          preload="auto"
        >
          您的浏览器不支持video
        </video>
        <div className={style['for-mobile']}>
          <CdnImage className={cn(style.mapImg)} src="https://devcdn.xylink.com/xylink_website_doc/static_video/landing-mobi.gif" alt="map" />
        </div>
        <div className={cn(style['for-mobile'], style.mapText)}>
          <span className={style.number}>每一天</span>
          <br />
          在全球近<span className={style.number}>百个</span>国家
          <br />近<span className={style.number}>千座</span>城市
          <br />有<span className={style.number}>十万多家</span>客户
          <br />
          使用<span className={style.number}>小鱼易连</span>云视频
        </div>
      </section>
      <section className={style.newsWrapper}>
        <header>
          <div className={style.title}>公司新闻</div>
          <CustomLink isNextLink={false} href={'/news'}>
            <div className={style.subTitle}>
              <span>更多新闻</span>&nbsp;&gt;
            </div>
          </CustomLink>
        </header>
        <div className={style.inner}>
          <Row gutter={[{ xs: 0, sm: 0, md: 20 }, 0]}>
            {news.filter(Boolean).map((item: any) => {
              return (
                <Col key={item.id} span={24} md={8} lg={8}>
                  <CustomLink isNextLink={false} href={`/news/${item.id}`}>
                    <div className={style.news}>
                      <img className={style.poster} src={item.newsCover} alt={item.newsTitle} />
                      <div className={style.detail}>
                        <p className={style.title}>{item.newsTitle}</p>
                        <div className={style.sub}>
                          <div className={style.desc}>{item.newsSummary}</div>
                        </div>
                      </div>
                    </div>
                  </CustomLink>
                </Col>
              );
            })}
          </Row>
        </div>
      </section>
      <section className={style.solutionWrapper}>
        <header className={cn(style['for-mobile'], style.header)}>
          <div className={style.title}>解决方案</div>
          <CustomLink isNextLink={false} href="/gov-solution">
            <div className={style.subTitle}>
              <span>更多解决方案</span>&nbsp;&gt;
            </div>
          </CustomLink>
        </header>
        <div className={style.inner}>
          <Row gutter={[0, { xs: 5, sm: 5, md: 0 }]}>
            {solutionList.map((item: ISolution) => {
              return (
                <Col span={24} md={12} lg={4} key={item.key}>
                  <CustomLink isNextLink={false} href={item.link}>
                    <div className={cn(style.solution, style[item.key])}>
                      <div className={style.mask}>
                        <div className={style.content}>
                          <div className={style.title}>{item.text}</div>
                          <div className={cn(style.line, style['for-pc'])} />
                          <div className={cn(style.viewDetail, style['for-pc'])}>查看详情</div>
                        </div>
                      </div>
                    </div>
                  </CustomLink>
                </Col>
              );
            })}
          </Row>
        </div>
      </section>
      <section className={style.partnerWrapper}>
        <header className={style.header}>
          <div className={style.title}>和他们一起选择小鱼易连</div>
        </header>
        <div className={style.inner}>
          <CdnImage styles={{ width: '100%', height: '100%' }} src={size.width < 768 ? mPartnerImg : pcPartnerImg} alt="partners" />
        </div>
      </section>
      <ContactBtnGroup />
      <Modal
        title=" "
        closeIcon={<Image className={style.closeIcon} src="/v2/images/home/live-closer.png" alt="font" />}
        visible={videoVisible}
        centered={true}
        footer={null}
        className={style.videoModal}
        onCancel={() => playVideo(false)}
      >
        <video ref={videoRef} className={style.videoPlayer} controls autoPlay src={mp4Url}>
          您的浏览器不支持video
        </video>
      </Modal>
    </Layout>
  );
}
export const getStaticProps: GetStaticProps = async context => {
  const res = await actionServer.getHomeNews();
  return {
    props: {
      news: res || [],
    },
    revalidate: 30,
  };
};
